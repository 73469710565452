import { FaLinkedin, FaGithub } from "react-icons/fa";
import '../styles/SocialIcons.css';

export default function SocialIcons() {
    const handleClick = (caller) => {
        switch(caller){
            case "linkedin": 
                window.open("https://www.linkedin.com/in/drengland1979/", "_blank");
                break;
            case "github": 
                window.open("https://github.com/drengland1979", "_blank");
                break;
            default:
                window.open("https://drengland.co.uk", "_self");
        }
    }
    return (
        <div>
            <div className="icons">
                <FaLinkedin onClick={() => handleClick("linkedin")} />
            </div>
            <div className="icons">
                <FaGithub onClick={() => handleClick("github")} /> 
            </div>
        </div>
    )
}