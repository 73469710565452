import React from 'react'
import SocialIcons from './components/SocialIcons';
import Headshot from './images/dave-no-bg.png';
import WindowSize from './components/WindowSize';
import './styles/App.css';

function App() {
  const size = WindowSize();
  return (
    <div className="App">
      {size.width <= 985 &&
        <div className="top-section">
          <h2>Dave England</h2> <h4>MBCS | CC | PSM1</h4>
          <p>Senior Software Engineer & Scrum Master</p>
        </div>
      }
      <div className="left-section">
        {size.width > 985 &&
          <div className="title">
            <h2>Dave England</h2> <h4>MBCS | CC | PSM1</h4>
            <p>Senior Software Engineer & Scrum Master</p>
          </div>
        }
      </div>
      <div className="center-section">
        <div className="headshot-image">
          <div className="headshot-image-egg">
            <img src={Headshot} alt="react logo" />
          </div>
        </div> 
      </div>
      <div className="right-section">
        <div className="icons-container">
          <SocialIcons />
        </div>
      </div> 
      <div className='line-container-right'>
        <div className="right-line"></div>
      </div>
      <div className='line-container-left'>
        <div className="left-line"></div>
      </div>
    </div>
  );
}

export default App;
